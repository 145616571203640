.product-item {
    $el: &;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: 100%;
    border: 1px solid map-deep-get($colors, "gray", "light");
    border-radius: rem-calc(10);
    padding: rem-calc(10);
    overflow: hidden;
    position: relative;
    transition: $transition-default;

    &__out-of-stock {
        background-color: map-deep-get($colors, "gray", "light");
        color: map-deep-get($colors, "out_of_stock", "default");
        text-transform: uppercase;
        font-weight: 700;
        font-size: .7rem;
        display: inline-block;
        border-radius: 6px;
        padding: .2rem .7rem;
        margin: .4rem 0;
        text-align: center;
    }

    &:hover {
        border: 1px solid map-deep-get($colors, "gray", "default");
        box-shadow: 0 0 5px 0 map-deep-get($colors, "gray", "default");

        #{$el}__button {
            background-color: map-deep-get($colors, "black", "default");
        }
    }

    @include breakpoint(large) {
        padding: rem-calc(24);
    }

    &__image-wrapper {
        flex: 1 0 100%;
        width: 100%;
        height: rem-calc(200);
        position: relative;
    }

    &__bg {
        border-radius: $border-radius-default;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__content {
        flex: 1 0 100%;
        width: 100%;
        margin-top: rem-calc(24);
        //margin-bottom: rem-calc(48);
    }

    &__per {
        font-size: 12px;
    }

    &__actions {
        flex: 1 0 100%;
        width: 100%;
    }

    &__button {
        margin-top: rem-calc(16);
    }

    &__overlay-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* stylelint-disable */
        span {
            display: none;
        }
        /* stylelint-enable */
    }
}
