.product-config-form {
    text-align: center;

    &__row {
        margin-top: rem-calc(40);
    }

    &__row-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: rem-calc(20);

        @include breakpoint(xlarge) {
            flex-wrap: nowrap;
        }
    }

    &__row-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;

        @include breakpoint(xlarge) {
            width: 100%;
        }
    }

    &__row-value {
        width: rem-calc(100);
    }

    &__row-caption {
        margin-right: rem-calc(10);
    }

    &__row-measure-type {
        font-weight: 700;
        margin-left: rem-calc(10);
    }

    &__select {
        width: 100%;
        max-width: rem-calc(500);
        margin: 0 auto;
    }

    &__textarea {
        width: 100%;
        max-width: rem-calc(500);
        margin: 0 auto;
    }

    &__file-label {
        width: 100%;
        max-width: rem-calc(500);
        margin: 0 auto;
        border: 3px dashed map-deep-get($colors, "gray", "default");
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: $border-radius-default;
        font-size: rem-calc(40);
        color: map-deep-get($colors, "gray", "default");
        cursor: pointer;
        height: rem-calc(200);
    }

    &__file-input {
        position: absolute;
        left: rem-calc(-9999);
        opacity: 0;
    }

    &__buttons {
        margin-top: rem-calc(40);
    }
}