.quotation-list {
    align-items: flex-end;
}

.shoppingcart-delivery-costs {

    position: relative;

    label {
        display: inline-block;
        margin-right: 10px;
        padding-top: 6px;
        text-align: right;
        width: 63%;
    }

    input {
        float: right;
        text-align: right;
        width: 30%;
    }
}
