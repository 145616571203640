.product-config-filters {
    $elm: &;
    padding-top: rem-calc(60);
    position: relative;

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        color: map-deep-get($colors, "green", "default");
    }

    &__filter {
        max-width: rem-calc(560);
        margin: 0 auto rem-calc(32);
    }

    &__range {
        margin-top: rem-calc(16);
    }

    &__range-values {
        display: flex;
        justify-content: space-between;
        line-height: 20px;
        margin-top: rem-calc(12);
    }

    &__range-value-wrapper {
        position: relative;
        max-width: 46%;
    }

    &__range-value {
        height: 20px;
        width: auto;
        min-width: 65px;
        max-width: 100%;
        padding: 3px 0px 3px 3px;
        border-color: white;

        &:not(:disabled) {
            &:focus,
            &:active,
            &:hover {
                border-color: #ccc;

                & + #{$elm}__range-unit {
                    display: none;
                }
            }
        }
    }

    &__range-unit {
        position: absolute;
        top: 0;
        right: 2px;
    }

    &__buttons {
        text-align: center;
    }
}
