.order-deliveryform {

    $order-deliveryform: &;

    /* stylelint-disable */
    .form__group {
        margin-bottom: rem-calc(24);
    }
    /* stylelint-enable */

    &__section {
        margin-top: rem-calc(40);
        padding-top: rem-calc(30);
        border-top: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__heading {
        margin-bottom: rem-calc(10);
    }

    &__disclaimer {
        color: map-deep-get($colors, "validation", "invalid");
    }

    &__checkbox-inline {
        /* stylelint-disable */
        .form__valueWrapper {
            display: inline-block;
            margin-right: rem-calc(10);
        }
        /* stylelint-enable */
    }

    &--ignore-group-margin {
        /* stylelint-disable */
        .form__group {
            margin-bottom: 0;
        }
        /* stylelint-enable */

        #{$order-deliveryform}__disclaimer {
            margin-bottom: 0;
        }
    }

    &__other-input {
        /* stylelint-disable */
        label {
            font-weight: 400;
        }
        /* stylelint-enable */
    }
}
