.account-orders-list {
    &__link {
        color: map-deep-get($colors, "black", "default");
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        background-color: map-deep-get($colors, "gray", "light");
        border-radius: $border-radius-default;
        transition: $transition-default;
        width: 100%;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "green", "lighter");
        }
    }

    &__item {
        margin-bottom: rem-calc(8);
    }

    &__content {
        @include breakpoint(medium) {
            display: flex;
            align-items: center;
        }
    }

    &__date {
        margin-right: rem-calc(16);
        font-size: rem-calc(12);
        flex-shrink: 0;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        small {
            font-weight: normal;
        }
    }
}
