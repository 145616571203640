.product-config-step {
    border-left: 1px solid map-deep-get($colors, "gray", "default");
    border-right: 1px solid map-deep-get($colors, "gray", "default");
    border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    border-radius: $border-radius-default;
    $product-config-step: &;

    &:first-of-type {
        border-top: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__title-section {
        height: rem-calc(100);
        padding: 30px 20px;
        display: flex;
        align-items: center;
        transition: $transition-default;

        @include breakpoint(xlarge) {
            padding: 30px 50px;
        }

        &--linkable {
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: map-deep-get($colors, "green", "lightest");

                #{$product-config-step}__number {
                    background-color: map-deep-get($colors, "blue", "default");
                    color: map-deep-get($colors, "white", "default");
                }
            }
        }
    }

    &__title {
        color: map-deep-get($colors, "gray", "darker");
    }

    &__number {
        width: rem-calc(40);
        height: rem-calc(40);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: map-deep-get($colors, "green", "lightest");
        color: map-deep-get($colors, "blue", "default");
        margin-right: rem-calc(15);
        flex-shrink: 0;
        transition: $transition-default;
    }

    &__content {
        padding: 30px 20px;
        display: none;
        position: relative;

        @include breakpoint(xlarge) {
            padding: 30px 50px;
        }
    }

    &--active {
        #{$product-config-step}__title-section {
            background-color: map-deep-get($colors, "green", "lightest");
            border-bottom: 1px solid map-deep-get($colors, "gray", "default");
        }

        #{$product-config-step}__number {
            background-color: map-deep-get($colors, "blue", "default");
            color: map-deep-get($colors, "white", "default");
        }

        #{$product-config-step}__title {
            color: map-deep-get($colors, "blue", "default");
        }

        #{$product-config-step}__content {
            display: block;
        }
    }
}
