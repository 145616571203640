.button {
    padding: 8px 24px;
    min-height: rem-calc(40);
    border-radius: 30px;
    background-color: map-deep-get($colors, "green", "default");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-left: rem-calc(-8);
        margin-right: rem-calc(8);
        fill: map-deep-get($colors, "white", "default");
    }

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "black", "default");
    }

    &--dark {
        background-color: map-deep-get($colors, "blue", "default");

        svg {
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "black", "default");
            color: map-deep-get($colors, "white", "default");
        }
    }

    &--orange {
        background-color: map-deep-get($colors, "orange", "default");

        svg {
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "black", "default");
            color: map-deep-get($colors, "white", "default");
        }
    }

    &--white {
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "black", "default");
        border-color: map-deep-get($colors, "gray", "dark");
        padding: 8px 16px;

        svg, svg path {
            fill: map-deep-get($colors, "black", "default");
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "green", "lightest");
            border-color: map-deep-get($colors, "green", "lightest");
            color: inherit;
        }
    }

    &--warning {
        background-color: map-deep-get($colors, "validation", "invalid");

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "validation", "invalid-dark");
        }
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--icon {
        padding-right: 10px;
        padding-left: 10px;
        justify-content: center;
        width: rem-calc(42);
        height: rem-calc(42);

        svg {
            margin-left: 0;
            margin-right: 0;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &--disabled {
        background-color: map-deep-get($colors, "gray", "default");
        pointer-events: none;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "gray", "default");
        }
    }

    @media print {
        display: none;
    }
}
