.shoppingcart-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: map-deep-get($colors, "green", "lightest");
    padding: 12px 24px 12px 12px;
    border-radius: $border-radius-default;
    margin-bottom: rem-calc(16);

    @include breakpoint(medium) {
        flex-wrap: nowrap;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 100%;

        @include breakpoint(medium) {
            width: 60%;
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: rem-calc(16);

        @include breakpoint(medium) {
            justify-content: space-between;
            margin-top: 0;
        }

        &--variable-price {
            justify-content: flex-end;
        }
    }

    &__image-wrapper {
        width: rem-calc(120);
        margin-right: rem-calc(16);
        flex-shrink: 0;
        vertical-align: top;
        align-self: flex-start;
        display: none;

        @include breakpoint(small-medium) {
            display: block;
        }
    }

    &__details {
        margin-right: rem-calc(16);
    }

    &__image {
        width: 100%;
    }

    &__quantity {
        width: rem-calc(60);
    }

    &__price {
        font-weight: 700;
        margin-left: rem-calc(16);

        @include breakpoint(medium) {
            margin-left: 0;
        }

        &:only-child {
            margin-left: auto;
            justify-content: flex-end;
        }
    }

    &__text {
        margin: 0;
    }

    &__delete {
        line-height: 1;
        margin-left: rem-calc(16);

        @include breakpoint(medium) {
            margin-left: 0;
        }

        svg {
            width: rem-calc(20);
            fill: map-deep-get($colors, "validation", "invalid-dark");
        }
    }

    &__title {
        margin-right: 1rem;
    }

    &__out-of-stock {
        margin-right: 1rem;
    }
    &__search_alternative_product {
        text-decoration: underline;
        color: #000;
        font-size: .9rem;
        font-weight: normal;
        text-wrap: none;
        white-space: nowrap;
    }
}
