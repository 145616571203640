.shoppingcart-editable-price {
    position: relative;
    display: inline-flex;
    border: 1px solid map-deep-get($colors, "gray", "default");
    transition: border .3s ease-in-out;

    &:hover {
        border: 1px solid map-deep-get($colors, "green", "default");
    }

    &__icon {
        border-right: 1px solid map-deep-get($colors, "gray", "default");

        svg {
            width: rem-calc(12);
            height: rem-calc(12);

            path {
                fill: map-deep-get($colors, "black", "default");
            }
        }
    }

    &__button {
        padding: 0 rem-calc(4);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    &__input {
        width: auto;
        padding: 0 rem-calc(10);
    }

    &__input-label {
        display: inline-flex;
        line-height: rem-calc(20);
    }

    &__input-text {
        width: auto;
        height: auto;
        border: none;
        padding: 0;
        line-height: rem-calc(20);
        text-align: right;

        &:focus {
            ouline: none;
            border: none;
        }
    }
}
